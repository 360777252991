export const TEMPLATES = [
  'ArticleLightWithSidebar',
  'ArticleWithSidebarV2',
  'Article3Column',
  'Article3Column-D1',
  'Article3Column-D2',
  'Article3Column-D3'
]

export const GSD_THEME = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
  AB_SPLIT: 'ab'
}

export const PAGE_TYPES = {
  Articles: 'article',
  Guides: 'guide',
  Profiles: 'profile'
}

export const EXPERT_EASE_CATEGORIES = {
  Insurance: 'Insurance',
  'Loans and Credit': 'Loans & Credit',
  Utilities: 'Utilities',
  'Savings and Super': 'Savings & Super',
  'Life events': 'Life events'
}

export const EXPERT_EASE_LOAD_MORE_PER_PAGE = 8
export const EXPERT_EASE_AUTHOR_URL = '/expert-analysis/author'

export const PERKS_LOAD_MORE_PER_PAGE = 8
export const OFFERS_LOAD_MORE_PER_PAGE = 8

export const MEDIA_LOAD_MORE_PER_PAGE = 8
export const MEDIA_AUTHOR_URL = '/media/author'

export const MEDIA_CATEGORIES = {
  press_release: 'Press release',
  research: 'Research'
}

export const FEATURED_IMAGE_DIMENSIONS = (template, breakpoint) => {
  return getKey(template, {
    default: {},
    ArticleLightWithSidebar: getKey(breakpoint, {
      default: {
        width: 382,
        height: 255
      },
      sm: {
        width: 608,
        height: 406
      },
      md: {
        width: 736,
        height: 491
      },
      lg: {
        width: 692,
        height: 462
      },
      'xl,2xl': {
        width: 852,
        height: 568
      }
    }),
    ArticleWithSidebarV2: getKey(breakpoint, {
      default: {
        width: 382,
        height: 177
      },
      sm: {
        width: 608,
        height: 177
      },
      md: {
        width: 736,
        height: 233
      },
      lg: {
        width: 608,
        height: 340
      },
      'xl,2xl': {
        width: 736,
        height: 340
      }
    })
  })
}

export const DISCLAIMER = {
  [VERTICAL.HOME_LOANS]: 'Compare Club partners with Mortgage Broker professionals across the country who have access to over 35 lenders and hundreds of products. The Comparison Rate is only true for the example given and may not include all fees and charges. Different fees, terms, or other loan amounts may result in a different Comparison Rate. In some situations, customers may not be able to access the interest rate quoted above. The actual interest rate offered to you will be dependent on a review of your circumstances, as well as the panel of lender accreditations available to the Broker you are referred to. Rates and fees will vary from one lender to another, dependent on your individual circumstances, and are subject to individual lender policy, assessment and approval. Speak to a Home Loan Adviser to find out your eligible rates, fees and lender products. Interest Rates are correct as at ' + VERTICALS[VERTICAL.HOME_LOANS].cashRates.date + '. Different terms, fees or other loan amounts might result in a different comparison rate. Terms, conditions, fees and charges apply and your full financial situation would need to be reviewed prior to acceptance of any offer or product. Rate Comparison is a business name of Beat My Loan Pty Ltd ABN 85 613 044 581. Beat My Loan Pty Ltd is an Authorised Credit Representative (Credit Rep Number 489939) of Alternative Media Pty Ltd ABN 17 149 089 716 (Australian Credit Licence Number 486326).',

  [VERTICAL.LIFE_INSURANCE]: 'We do not compare all life insurers or products in the market. Any advice provided in this website is of general nature and does not take into account your needs, objectives or financial situation. You should consider the appropriateness of it (and any relevant product) before proceeding, and read the relevant Product Disclosure Statement for the product. For more information about the range of insurers, how Life Insurance Comparison works for you, and how we make our money (keep in mind our quotes are free for you to utilise), click here and take the time to read our FSG. The financial products compared on this website do not necessarily compare all features that may be relevant to you. Comparisons are made on the basis of price only and different products may have different features and different levels of coverage.',

  [VERTICAL.CREDIT_CARDS]: 'Compare Club compares over a range of credit cards from Big Banks, Specialist finance providers and directly from payment issuers. Compare Club doesn\'t compare all cards on the market and not all cards are available to all people. Our panel of credit cards will change over time. Customers should consider their personal circumstances before applying for credit.',

  [VERTICAL.PERSONAL_LOANS]: 'Compare Club Personal Loans is an online financial comparison service and is owned and operated by Compare Club Australia Pty Ltd (ACN: 634 600 007). We have partnered with Monevo to provide you access to technology that allows you to select personal loans from their panel of providers.<br/>As our customer you\'ll be provided with quotes directly from the lender for the product you intend to purchase. We do not charge you a fee for the service we provide. The lender remunerates us and Monevo in return for setting up your loan.</br>The financial products compared on this website do not necessarily compare all features that may be relevant to you. Comparisons are made on the basis of price only and different products may have different features.',

  [VERTICAL.CAR_LOANS]: 'Compare Club has partnered with CarClarity, Australian Credit License 478874.<br/><br/> CarClarity has commercial relationships with 30+ car finance lenders & over 1000 car finance products, but does not compare all products in market. Availability of lenders and products can vary dependent on the individuals personal credit history and circumstances. CarClarity and Compare Club may receive a commission from the lender to provide this service.',

  [VERTICAL.CAR_INSURANCE]: 'Compare Club Car Insurance is an online financial comparison service and is owned and operated by Compare Club Australia Pty Ltd (ACN: 634 600 007). Compare Club does not compare all brands or all products offered by all brands. <br/><br/> The financial products compared on this website do not necessarily compare all features that may be relevant to you. Comparisons are made on the basis of price only and different products may have different features.<br/><br/>The information on this page is opinion only. Please check with a financial professional before you make any major financial decisions.<br/><br/>GENERAL ADVICE WARNING: General Advice is advice that has been prepared without considering your current objectives, financial situation or needs. Therefore, before acting on this advice, you should consider the appropriateness of the advice having regard to those objectives, situation or needs.',

  [VERTICAL.ENERGY]: '<a href="/" class="text-primary underline" target="_blank"><b>CompareClub.com.au</b></a> is an on-line energy comparison service and is owned and operated by Compare Club Australia Pty Ltd (ACN: 634 600 007). We provide offers on behalf of a number of energy retailers, and have also partnered with Zembl to provide you access to their expert staff to assist in selecting the most appropriate energy packages from their panel of providers. Compare Club doesn’t compare all products from all retailers and not all products are available to all customers. In providing a comparison, we don’t necessarily compare all features that may be relevant to you. Please consider the individual product terms and conditions carefully to ensure the product is right for you.'
}

export const RELATED_ARTICLES = {
  [VERTICAL.HEALTH_INSURANCE]: [
    {
      title: 'Types of Cover',
      links: [
        {
          text: 'Ambulance cover',
          url: 'https://compareclub.com.au/health-insurance/ambulance-cover/'
        },
        {
          text: 'Extras Cover',
          url: 'https://compareclub.com.au/health-insurance/extras-cover/'
        },
        {
          text: 'Hospital Cover',
          url: 'https://compareclub.com.au/health-insurance/hospital-cover/'
        },
        {
          text: 'Overseas visitors health cover',
          url: 'https://compareclub.com.au/health-insurance/visitors-visa-ovhc/'
        }
      ]
    },
    {
      title: 'Health Insurance Cover For',
      links: [
        {
          text: '30 Year Olds health insurance',
          url: 'https://compareclub.com.au/health-insurance/private-health-30-year-olds/'
        },
        {
          text: 'Couples health insurance',
          url: 'https://compareclub.com.au/health-insurance/couples/'
        },
        {
          text: 'Familes health insurance',
          url: 'https://compareclub.com.au/health-insurance/families/'
        },
        {
          text: 'Over 50s health insurance',
          url: 'https://compareclub.com.au/health-insurance/over-50s-guide/'
        },
        {
          text: 'Pregnancy health insurance',
          url: 'https://compareclub.com.au/health-insurance/pregnancy/'
        },
        {
          text: 'Seniors health insurance',
          url: 'https://compareclub.com.au/health-insurance/seniors/'
        },
        {
          text: 'Singles health insurance',
          url: 'https://compareclub.com.au/health-insurance/singles/'
        }
      ]
    },
    {
      title: 'Extras Cover',
      links: [
        {
          text: 'Dental cover',
          url: 'https://compareclub.com.au/health-insurance/dental/'
        },
        {
          text: 'Laser Eye Surgery cover',
          url: 'https://compareclub.com.au/health-insurance/laser-eye-surgery/'
        },
        {
          text: 'Mental Health cover',
          url: 'https://compareclub.com.au/health-insurance/mental-health/'
        },
        {
          text: 'Optical cover',
          url: 'https://compareclub.com.au/health-insurance/optical/'
        },
        {
          text: 'Orthodontics cover',
          url: 'https://compareclub.com.au/health-insurance/orthodontics/'
        },
        {
          text: 'Weight Loss cover',
          url: 'https://compareclub.com.au/health-insurance/weight-loss-surgery/'
        }
      ]
    },
    {
      title: 'Insurers',
      links: [
        {
          text: 'AHM health insurance',
          url: 'https://compareclub.com.au/health-insurance/ahm/'
        },
        {
          text: 'Australian Unity health insurance',
          url: 'https://compareclub.com.au/health-insurance/australian-unity/'
        },
        {
          text: 'BUPA health insurance',
          url: 'https://compareclub.com.au/health-insurance/bupa/'
        },
        {
          text: 'Frank health insurance',
          url: 'https://compareclub.com.au/health-insurance/frank-health-insurance/'
        },
        {
          text: 'HCF health insurance',
          url: 'https://compareclub.com.au/health-insurance/hcf/'
        },
        {
          text: 'NIB health insurance',
          url: 'https://compareclub.com.au/health-insurance/nib/'
        },
        {
          text: 'Peoplecare health insurance',
          url: 'https://compareclub.com.au/health-insurance/peoplecare/'
        }
      ]
    }
  ],

  [VERTICAL.LIFE_INSURANCE]: [
    {
      title: 'Types of Cover',
      links: [
        {
          text: 'Funeral cover',
          url: 'https://compareclub.com.au/life-insurance/funeral-insurance/'
        },
        {
          text: 'Income protection',
          url: 'https://compareclub.com.au/life-insurance/income-protection-insurance/'
        },
        {
          text: 'Term life insurance',
          url: 'https://compareclub.com.au/life-insurance/term-or-life-insurance/'
        },
        {
          text: 'TPD insurance',
          url: 'https://compareclub.com.au/life-insurance/tpd-insurance/'
        },
        {
          text: 'Trauma insurance',
          url: 'https://compareclub.com.au/life-insurance/trauma-insurance/'
        },
        {
          text: 'Whole life insurance',
          url: 'https://compareclub.com.au/life-insurance/whole-life-insurance/'
        }
      ]
    },
    {
      title: 'Life Insurance For',
      links: [
        {
          text: 'Life insurance for diabetics',
          url: 'https://compareclub.com.au/life-insurance/diabetics/'
        },
        {
          text: 'Life insurance for families',
          url: 'https://compareclub.com.au/life-insurance/families/'
        },
        {
          text: 'Life insurance for over 50s',
          url: 'https://compareclub.com.au/life-insurance/over-50s/'
        },
        {
          text: 'Life insurance for over 65s',
          url: 'https://compareclub.com.au/life-insurance/over-65/'
        },
        {
          text: 'Life insurance for singles',
          url: 'https://compareclub.com.au/life-insurance/singles/'
        },
        {
          text: 'Life insurance for smokers',
          url: 'https://compareclub.com.au/life-insurance/smokers/'
        },
        {
          text: 'Life insurance for women',
          url: 'https://compareclub.com.au/life-insurance/women/'
        }
      ]
    },
    {
      title: 'Insurers',
      links: [
        {
          text: 'AIA life insurance',
          url: 'https://compareclub.com.au/life-insurance/aia/'
        },
        {
          text: 'Clearview life insurance',
          url: 'https://compareclub.com.au/life-insurance/clearview/'
        },
        {
          text: 'Integrity life insurance',
          url: 'https://compareclub.com.au/life-insurance/integrity/'
        },
        {
          text: 'MetLife life insurance',
          url: 'https://compareclub.com.au/life-insurance/metlife/'
        },
        {
          text: 'MLC life insurance',
          url: 'https://compareclub.com.au/life-insurance/mlc/'
        },
        {
          text: 'NEOS life insurance',
          url: 'https://compareclub.com.au/life-insurance/neos/'
        },
        {
          text: 'OnePath life insurance',
          url: 'https://compareclub.com.au/life-insurance/onepath/'
        },
        {
          text: 'TAL life insurance',
          url: 'https://compareclub.com.au/life-insurance/tal/'
        },
        {
          text: 'Zurich life insurance',
          url: 'https://compareclub.com.au/life-insurance/zurich/'
        }
      ]
    }
  ],

  [VERTICAL.ENERGY]: [
    {
      title: 'Types of Cover',
      links: [
        {
          text: 'Electricity',
          url: 'https://compareclub.com.au/energy/electricity-pricing-structure/'
        },
        {
          text: 'Gas',
          url: 'https://compareclub.com.au/energy/gas-charges/'
        },
        {
          text: 'Solar',
          url: 'https://compareclub.com.au/energy/solar-save-energy-money/'
        }
      ]
    },
    {
      title: 'Energy By State',
      links: [
        {
          text: 'New South Wales',
          url: 'https://compareclub.com.au/energy/compare-energy-nsw/'
        },
        {
          text: 'Victoria',
          url: 'https://compareclub.com.au/energy/compare-energy-vic/'
        },
        {
          text: 'Queensland',
          url: 'https://compareclub.com.au/energy/compare-energy-qld/'
        },
        {
          text: 'South Australia',
          url: 'https://compareclub.com.au/energy/compare-energy-sa/'
        },
        {
          text: 'Australian Capital Territory',
          url: 'https://compareclub.com.au/energy/compare-energy-act/'
        },
        {
          text: 'Tasmania',
          url: 'https://compareclub.com.au/energy/compare-energy-tas/'
        }
      ]
    }
  ],

  [VERTICAL.HOME_LOANS]: [
    {
      title: 'By Type',
      links: [
        {
          text: 'Fixed Rate',
          url: 'https://compareclub.com.au/home-loans/fixed-rate/'
        },
        {
          text: 'Variable Rate',
          url: 'https://compareclub.com.au/home-loans/variable/'
        },
        {
          text: 'Refinancing',
          url: 'https://compareclub.com.au/home-loans/refinancing/'
        },
        {
          text: 'First Home',
          url: 'https://compareclub.com.au/home-loans/everything-to-know-buying-first-home/'
        }
      ]
    },
    {
      title: 'Calculators',
      links: [
        {
          text: 'Refinance',
          url: 'https://compareclub.com.au/home-loans/calculators/refinance/'
        },
        {
          text: 'Borrowing Power',
          url: 'https://compareclub.com.au/home-loans/calculators/borrowing-power/'
        },
        {
          text: 'Stamp Duty',
          url: 'https://compareclub.com.au/home-loans/calculators/stamp-duty/'
        },
        {
          text: 'Buying & Selling Costs',
          url: 'https://compareclub.com.au/home-loans/calculators/buying-and-selling-costs/'
        },
        {
          text: 'Deposit Calculator',
          url: 'https://compareclub.com.au/home-loans/calculators/deposit/'
        },
        {
          text: 'Budget Planner',
          url: 'https://compareclub.com.au/home-loans/calculators/budget-planner/'
        },
        {
          text: 'Living Expenses Planner',
          url: 'https://compareclub.com.au/home-loans/calculators/living-expenses-planner/'
        }
      ]
    }
  ],

  [VERTICAL.CAR_INSURANCE]: [
    {
      title: 'By Type',
      links: [
        {
          text: 'Comprehensive',
          url: 'https://compareclub.com.au/car-insurance/comprehensive'
        },
        {
          text: 'Pay Per Kilometre',
          url: 'https://compareclub.com.au/car-insurance/disclose-pay-per-km-usage-for-insurance-savings'
        },
        {
          text: 'Third Party Damage',
          url: 'https://compareclub.com.au/car-insurance/third-party-property-damage'
        },
        {
          text: 'Third Party Fire & Theft',
          url: 'https://compareclub.com.au/car-insurance/third-party-fire-and-theft'
        }
      ]
    }
  ]
}
